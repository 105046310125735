<template>
  <div class="app-questions list">
    <PageHeader
      :title="`Sondages ${domain.label}`"
      description="Choix des sondages issues de la base de médias Maviepro"
    />
    <SurveysSubmenu />
    <PageContent>
      <MVPTable :gridData="gridData" :options="gridOptions" />
    </PageContent>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import { mapGetters } from 'vuex'

import MVPTable from '@/components/agGrid/MVPTable.vue'
import AG_GRID_LOCALE_FR from '@/assets/ag-grid.locale.fr.json'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import ButtonCellRenderer from '@/components/agGrid/ButtonCellRenderer.vue'
import SurveysSubmenu from './SurveysSubmenu.vue'
import ListRowDetail from './ListRowDetail.vue'

export default {
  name: 'app-surveys-media-mvp',
  components: {
    PageHeader,
    SurveysSubmenu,
    PageContent,
    ButtonCellRenderer, //eslint-disable-line
    ListRowDetail, //eslint-disable-line
    MVPTable
  },
  computed: {
    ...mapGetters([
      'roles',
      'user',
      'domain'
    ]),
    domain () {
      this.getSurveys()
      return this.$store.getters.domain
    }
  },
  data () {
    return {
      // Define necessary data properties
      surveys: [],
      gridData: null,
      gridOptions: {
        rowModelType: 'clientSide',
        localeText: AG_GRID_LOCALE_FR,
        masterDetail: true,
        detailCellRenderer: 'ListRowDetail',
        detailRowAutoHeight: true,
        columnDefs: [
          {
            headerName: 'Question',
            field: 'question'
            // cellRenderer: 'agSetColumnFilter',
            // sortable: true
          },
          {
            headerName: 'Date de publication par Maviepro',
            field: 'created_at',
            cellRenderer: 'DateCellRenderer'
          },
          {
            headerName: 'Action',
            field: 'button',
            cellRenderer: 'ButtonCellRenderer',
            cellRendererParams: {
              onClick: this.duplicate
            },
            width: 200
          }
        ],
        defaultColDef: {
          resizable: true,
          floatingFilter: true
        }
      }
    }
  },
  mounted () {
    this.getSurveys()
  },
  methods: {
    getSurveys () {
      this.loading = true
      http.get('/surveys/surveys-mvp').then(res => {
        this.loading = false
        this.surveys = res.data
        this.gridData = res.data
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des sondages',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    duplicate (survey) {
      this.$buefy.dialog.confirm({
        title: 'Duplication',
        message: 'Voulez-vous vraiment dupliquer ce sondage ?<br><br>En confirmant, le sondage sera automatiquement dupliqué sur votre domaine et vous serez redirigé vers l\'édition de ce dernier.',
        confirmText: 'Dupliquer',
        cancelText: 'Annuler',
        type: 'is-danger',
        onConfirm: () => {
          http.post(`/surveys/${survey.uuid}/duplicate`).then(res => {
            this.$router.push({ path: `/app/surveys/${res.data.uuid}` })
          }, err => {
            console.log(err)
          })
        }
      })
    }
  }
}
</script>
