<script>
export default {
  methods: {
    calculateVotingRate (option) {
      const totalVotes = this.params.data.options.reduce((value, option) => value + option.answers_count, 0)
      return parseFloat(((option.answers_count / (totalVotes === 0 ? 1 : totalVotes)) * 100).toFixed(0))
    },

    copyUUIDToClipboard (uuid) {
      navigator.clipboard.writeText(uuid)
        .then(() => {
          this.$buefy.notification.open({
            message: 'Identifiant unique copié dans le presse-papiers.',
            type: 'is-success',
            position: 'is-bottom',
            duration: 5000
          })
        })
    }
  }
}
</script>

<template>
  <div
    class="p-4"
    v-if="params && params.data"
  >
    <div
      class="flex items-center justify-start gap-2"
      v-for="answer in params.data.options"
      :key="answer.uuid"
    >
      <div class="mr-10px w-1/12">
        <div class="w-full bg-gray-200 rounded-full h-3 dark:bg-gray-700 relative">
          <div
            class="bg-primary-200 h-3 rounded-full"
            :style="'width:' + calculateVotingRate(answer) || 0 + '%'"
          />
          <div class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-xs">
            {{ calculateVotingRate(answer) }}%
          </div>
        </div>
      </div>
      <t-tag
        tag-name="div"
        variant="badge"
      >
        {{ answer.answers_count }} votes
      </t-tag>
      <div class="text-sm">
        {{ answer.option }}
      </div>
    </div>
    <div class="flex mt-4 ml-8">
      <div class="font-bold mr-2">UUID</div>
      <div
        class="w-4/5 select-all"
        @click="copyUUIDToClipboard(params.data.uuid)"
      >{{ params.data.uuid }}</div>

    </div>

  </div>
</template>
